import { useEffect } from 'react';

export const MouseFlow = ({ children }) => {
  useEffect(() => {
    window._mfq = window._mfq || [];
    (function () {
      var mf = document.createElement('script');
      mf.type = 'text/javascript';
      mf.id = 'MouseFlow';
      mf.defer = true;
      mf.src =
        '//cdn.mouseflow.com/projects/0947f9f7-6cf6-4737-87dd-b2646904354e.js';
      document.getElementsByTagName('head')[0].appendChild(mf);
    })();

    return () => {
      const head = document.getElementsByTagName('head')[0];
      const el = head.querySelector('#MouseFlow');
      if (el) {
        head.removeChild(el);
      }
    };
  }, []);

  return <>{children}</>;
};
